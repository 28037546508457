import React from "react"
import { Link } from "gatsby"
import banner from "../../images/banner-cropped.jpg"
import {
  navbarDesktop,
  links,
  bannerStyle,
  mobileMenu,
  mobileMenuOpen,
} from "./styles.module.less"

const Navbar = () => {
  return (
    <nav>
      <div className={navbarDesktop}>
        <ul className={links}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/feedback">Feedback</Link>
          </li>
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <Link to="/articles">Articles</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <div
        style={{
          marginBottom: 30,
          paddingBottom: "32%",
          height: 0,
          backgroundColor: "#202020",
        }}
      >
        <img
          src={banner}
          className={bannerStyle}
          alt="UniverSAL Game Protection"
        />
      </div>
    </nav>
  )
}

export default Navbar

export const MobileMenu = ({ menuStatus }) => {
  return (
    <div className={menuStatus ? mobileMenuOpen : mobileMenu}>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/feedback">Feedback</Link>
        </li>
        <li>
          <Link to="/news">News</Link>
        </li>
        <li>
          <Link to="/articles">Articles</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  )
}
