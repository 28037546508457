import React from "react"

import { burger, burgerX } from "./styles.module.less"

export const Burger = ({ menuStatus, setMenuStatus }) => {
  return (
    <button
      className={menuStatus ? burgerX : burger}
      // menuStatus={menuStatus}
      onClick={() => {
        setMenuStatus(!menuStatus)
        console.log(menuStatus)
      }}
    >
      <div></div>
      <div></div>
      <div></div>
    </button>
  )
}
