import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Navbar from "./navbar"

import "./layout.css"
import "../fonts/stylesheet.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
        }}
      >
        <main style={{ padding: `1rem 1.0875rem 1.45rem` }}>
          <Navbar />
          {children}
        </main>
      </div>
      <footer>
        © {new Date().getFullYear()} UniverSal Game Protection
        Development,&nbsp;Inc. All&nbsp;rights&nbsp;reserved.
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
