import React from "react"
import Layout from "../layout"
import { Seo } from "../seo"
import { contactPage } from "./styles.module.less"

export default function ContactAlt() {
  return (
    <Layout>
      <main className={contactPage}>
        <p>
          For all inquiries please email Dee at{" "}
          <a href="mailto:dee@universalgameprotection.com">
            dee@universalgameprotection.com
          </a>
        </p>
      </main>
    </Layout>
  )
}

export const Head = () => <Seo title="Contact | Universal Game Protection" />
