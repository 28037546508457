import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "typeface-open-sans"
import { MobileMenu } from "./navbar"
import { Burger } from "./burger"
import { header } from "./headerStyles.module.less"

const Header = ({ siteTitle }) => {
  const [menuStatus, setMenuStatus] = useState(false)

  const node = useRef()
  useOnClickOutside(node, () => setMenuStatus(false))

  return (
    <header
      style={{
        background: `#383B3D`,
        marginBottom: `0`,
      }}
    >
      <div
        style={{
          margin: 0,
          padding: `1.45rem`,
        }}
        className={header}
      >
        <h1 className="headerTitle" style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <div ref={node}>
          <Burger menuStatus={menuStatus} setMenuStatus={setMenuStatus} />
          <MobileMenu menuStatus={menuStatus} setMenuStatus={setMenuStatus} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener("mousedown", listener)
    return () => {
      document.removeEventListener("mousedown", listener)
    }
  }, [ref, handler])
}
